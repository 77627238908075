module services {
    export module applicationcore {
        export class descriptionService implements interfaces.applicationcore.IDescriptionService {
            static $inject = ["$resource", "ENV", '$localStorage', '$q'];

            constructor(private $resource: ng.resource.IResourceService, private ENV: interfaces.applicationcore.serverConfig,
                private $localStorage: interfaces.applicationcore.ILocalStorage, private $q: ng.IQService) {
            }

            //Gets a list of all description
            private getList(): ng.resource.IResourceClass<ng.resource.IResource<interfaces.applicationcore.IDescription>> {
                var getList: ng.resource.IActionDescriptor = {
                    method: 'GET',
                    isArray: true
                }

                return <ng.resource.IResourceClass<ng.resource.IResource<interfaces.applicationcore.IDescription>>>
                    this.$resource(this.ENV.DSP_URL + "Description/GetDescriptionList", {

                    }, {
                            query: getList
                        });
            }

            loadDescriptions() {
                this.getList().query((data: Array<interfaces.applicationcore.IDescription>) => {
                    this.$localStorage.Descriptions = [];
                }, () => {

                });
            }


            getDescription(id: number): ng.IPromise<interfaces.applicationcore.IDescription> {
                var deffered = this.$q.defer<interfaces.applicationcore.IDescription>();

                if (!this.$localStorage.Descriptions || this.$localStorage.Descriptions.length === 0) {
                    deffered.resolve({ Id: 0, LocaleDescription: "#ERR" });
                }

                deffered.resolve({ Id: 0, LocaleDescription: "#ERR" });

                //_.find(this.$localStorage.Descriptions, (n, key) => {
                //    if (n.Id === id) {
                //        deffered.resolve(n);
                //        return;
                //    }

                //    if (this.$localStorage.Descriptions.length === key) {
                //        deffered.resolve({ Id: 0, LocaleDescription: "#ERR" });
                //    }
                //});

                return deffered.promise;
            }
        }
    }
    angular.module("app").service("descriptionService", services.applicationcore.descriptionService);
}